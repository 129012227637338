import React from 'react'
import { setLangString } from './lang-context-client'

// Types definition
export type LangCode = 'en' | 'fr'
export type I18nDictionary = { [key: string]: any }
export type I18nValue = { [lang: string]: string }

// Languages definition
export const LANGS: I18nDictionary = {
  'en': 'English',
  'fr': 'Français'
}

// CONSTANTS
export const DEFAULT_LANG_CODE: LangCode = 'fr'

// Local variable
let LANG_CODE: LangCode = DEFAULT_LANG_CODE


export function toLangCode(lang: string): LangCode {
  return (typeof lang === 'string' && LANGS[lang]) ? (lang as LangCode) : DEFAULT_LANG_CODE
}

export function setLangCode(lang: string): LangCode {
  LANG_CODE = toLangCode(lang)
  if (typeof window !== 'undefined') setLangString(LANG_CODE)
  return LANG_CODE
}

export function getLangCode(): LangCode {
  if (typeof window !== 'undefined') LANG_CODE = toLangCode(localStorage.getItem('lang') || '')
  return LANG_CODE
}

export function getLocalizedUri(path: string, lang?: string): string {
  if (typeof window !== 'undefined') LANG_CODE = toLangCode(localStorage.getItem('lang') || '')
  const langCode = lang || LANG_CODE
  const langUri = langCode === DEFAULT_LANG_CODE ? '' : '/' + langCode
  return langUri === '' ?
    (path === '' ? '/' : path) :
    langUri + path
}

// USE I18n
// ---------------------------------------------------------------------------------

const _getI18n = (input: I18nDictionary, lang: LangCode): I18nDictionary => {
  if (React.isValidElement(input)) return input
  if (Array.isArray(input)) return input.map(elt => _getI18n(elt, lang))
  if (typeof input === 'object') {
    if (typeof input[lang] !== 'undefined') return _getI18n(input[lang], lang)
    const res: { [key: string]: any } = {}
    for (const [key, value] of Object.entries(input))
      res[key] = _getI18n(value, lang)
    return res
  }
  return input
}

const _getI18nValue = (input: I18nValue, lang: LangCode): string => {
  return input[lang]
}

export function useI18n(I18nDictionary: I18nDictionary) {
  return _getI18n(I18nDictionary, getLangCode())
}

export function getI18n(I18nDictionary: I18nDictionary) {
  return _getI18n(I18nDictionary, getLangCode())
}

export function useI18nValue() {
  const i18nValue = (input: I18nValue) => _getI18nValue(input, getLangCode())
  return i18nValue
}

import { cn } from '@/lib/utils/utils'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'


export let BUTTON_VARIANTS: any

export const buttonVariants = cva(
  'inline-flex gap-2 flex-nowrap whitespace-nowrap items-center justify-center rounded-lg text-sm font-medium transition-all ' +
  'focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-transparent focus-visible:ring-offset-0 ' +
  'disabled:opacity-70 disabled:pointer-events-none disabled:cursor-default ring-offset-background select-none',
  {
    variants: BUTTON_VARIANTS = {
      variant: {
        primary: 'bg-primary text-primary-foreground hover:shadow-md',
        secondary: 'bg-secondary text-secondary-foreground hover:bg-accent hover:shadow-md',
        accent: 'bg-accent text-accent-foreground hover:shadow-md',
        muted: 'bg-muted text-muted-foreground',
        outline: 'border border-border hover:shadow-md',
        ghost: 'hover:shadow-md',
        link: 'underline-offset-4 hover:underline text-secondary-foreground',
        destructive: 'bg-destructive text-destructive-foreground hover:brightness-[120%] hover:shadow-md'
      },
      size: {
        xs: 'h-7 px-2 text-xs rounded-md',
        sm: 'h-9 px-3',
        md: 'h-10 py-2 px-4',
        lg: 'h-11 px-8 text-md',
        xl: 'h-12 px-12 text-lg'
      }
    },
    defaultVariants: {
      variant: 'primary',
      size: 'md'
    }
  }
)

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        ref={ref}
        className={cn(buttonVariants({ variant, size }), className)}
        {...props}
      />
    )
  }
)
Button.displayName = 'Button'


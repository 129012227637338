'use client'


import { useEffect } from 'react'

export function setLangString(lang: string): string {
  localStorage.setItem('lang', lang)
  return lang
}

export function LangClient({ lang }: { lang: string }) {

  useEffect(() => {
    setLangString(lang)
  }, [])

  return (
    <div></div>
  )
}

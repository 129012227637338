'use client'

import { RootState } from '@/app/store'
import { cn } from '@/lib/utils/utils'
import { ReactNode, useEffect, useRef } from 'react'
import { RiCloseLine } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux'
import { AnyAction } from 'redux'
import { Button } from './ui/button'


// Global content ----------------------------------------------------------------

const dialogGlobal: { content: ReactNode | null, onClose?: () => void, theme: string | undefined } = {
  content: null,
  onClose: () => { },
  theme: undefined
}

const setDialogGlobal = (content: ReactNode, onClose?: () => void, theme?: string) => {
  dialogGlobal.content = content
  dialogGlobal.onClose = onClose
  dialogGlobal.theme = theme
}


// Actions ------------------------------------------------------------------

export const OPEN_DIALOG = 'OPEN_DIALOG'
export const CLOSE_DIALOG = 'CLOSE_DIALOG'

export const openDialog = (): AnyAction => ({
  type: OPEN_DIALOG
})

export const closeDialog = (): AnyAction => ({
  type: CLOSE_DIALOG
})


// Reducer ------------------------------------------------------------------

type DialogState = { open: boolean }

const initialState: DialogState = { open: false }

const dialogReducer = (state = initialState, action: AnyAction): DialogState => {
  switch (action.type) {
    case OPEN_DIALOG:
      return { ...state, open: true }
    case CLOSE_DIALOG:
      return { ...state, open: false }
    default:
      return state
  }
}

export default dialogReducer


// Hook -------------------------------------------------------------------

export const useDialog = (): DialogState => {
  return useSelector((state: RootState) => state.dialog)
}

export const useDialogActions = () => {
  const dispatch = useDispatch()
  return {
    openDialog: ({ content, onClose, theme }: { content: ReactNode, onClose?: () => void, theme?: string }) => {
      setDialogGlobal(content, onClose, theme)
      dispatch(openDialog())
    },
    closeDialog: () => dispatch(closeDialog())
  }
}


// Container --------------------------------------------------------------

export const DialogContainer: React.FC = () => {

  const dialogRef = useRef<HTMLDivElement | null>(null)
  const dialog = useDialog()
  const { closeDialog } = useDialogActions()

  useEffect(() => {
    document.body.style.overflow = dialog?.open ? 'hidden' : 'unset'
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [dialog?.open])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target as Node)) {
        console.log('CLICKED OUSIDE DIALOG')
        if (dialogGlobal.onClose) dialogGlobal.onClose()
        closeDialog()
      }
    }
    dialog?.open ?
      window.addEventListener('mousedown', handleClickOutside) :
      window.removeEventListener('mousedown', handleClickOutside)
    return () => {
      window.removeEventListener('mousedown', handleClickOutside)
    }
  }, [dialog, closeDialog])

  if (!dialog?.open) return null
  return (
    <div id='FOG' className='max-w-screen max-h-real-screen fixed inset-0 z-[110] flex items-center justify-center overflow-hidden backdrop-blur-sm'>
      <div id='CONTAINER' ref={dialogRef} className={cn(
        'prettyScrollBar w-full sm:min-h-[100px] sm:w-fit sm:min-w-[100px]',
        'relative flex h-fit flex-col items-center overflow-hidden rounded-xl bg-background opacity-100 shadow-xl',
        typeof dialogGlobal.theme === 'string' ? (dialogGlobal.theme === 'light' ? 'text-black' : 'text-white') : '')}
      style={{ maxWidth: '90vw', maxHeight: '90vh', margin: 'auto' }}>
        {dialogGlobal.content}
        <Button variant='ghost' className='absolute right-1 top-1 h-8 w-12 p-0 sm:right-2 sm:top-2' onClick={closeDialog}>
          <RiCloseLine size={16} onClick={closeDialog} />
        </Button>
      </div>
    </div>
  )
}
